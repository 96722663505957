<template>
  <div id="resourcesOrigin" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container fluid class="base text-center">
      <v-row no-gutters class="titulo ml-10 mt-5">ORÍGENES DE RECURSO</v-row>
      <v-row no-gutters justify="end" class="mr-12">
        <v-col cols="12" lg="6" md="6" sm="10" xs="12">
          <v-card-title>
            <router-link to="/addResource" id="nuevoOrigen">
              <button class="breakSearchE botonAmarillo">Alta de orígen</button>
            </router-link>
            <v-text-field
              class="mr-2"
              v-model="search"
              append-icon="search"
              label="Buscador"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
        </v-col>
      </v-row>
      <v-row no-gutters class="datos pa-0" style="height: auto !important">
        <v-card style="width: 100%; background: rgb(199, 195, 199) !important">
          <v-data-table
            :headers="headers"
            :items="origins"
            :search="search"
             class="elevation-2 text--dark"
            loading-text="Cargando datos... Por favor espere"
            :items-per-page="5"
            :no-results-text="'No se encontraron incidencias'"
            :no-data-text="'No hay datos'"
            :footer-props="{
              'items-per-page-text': 'Orígenes por página',
              'items-per-page': [5, 10, 15],
              'items-per-page-all-text': 'Todos',
              'items-per-page-options': [5, 10],
              'loading-text': 'Obteniendo datos... Por favor espere',
              'no-data-text': 'No hay datos...',
              'no-results-text': 'No se encontraron incidencias'}"
          >
            <template v-slot:no-data>
              <v-alert :value="true" color="#ffffff" icon="warning">
                <p style="color: black">No hay datos en sistema ...</p>
              </v-alert>
            </template>
            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="item in items"
                  :key="item.id"
                  :class="{ selectedRow: item === selectedItem }"
                >
                  <td class="text-sm-center">{{ item.claveCFDI }}</td>
                  <td class="text-sm-center">{{ item.tipoOrigenRecurso }}</td>
                  <td class="justify-center layout px-0">
                    <v-icon
                      color="#717171"
                      small
                      class="mr-6"
                      @click="editItem(item)"
                      >fa-edit</v-icon
                    >
                    <v-icon color="red" small @click="temporal(item)"
                      >fa-trash-alt</v-icon
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </v-row>
    </v-container>
    <!--Editar item-->
    <v-dialog v-model="edit" width="350">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Edición de datos</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <div class="form-group mt-5 mb-5">
              <label for="idResource">Id Tipo de Recurso</label>
              <input
                type="text"
                name="idResource"
                id="idResource"
                placeholder="Id de Tipo de Recurso"
                class="ml-4 inputs"
                autocomplete="false"
                v-model="idResource"
                required
                maxlength="2"
                @keypress="isNumber($event)"
              />
            </div>
          </v-row>
          <v-row>
            <div class="form-group mb-1">
              <label for="description" class="mr-12">Descripción</label>
              <input
                type="text"
                name="description"
                id="description"
                placeholder="Descripción del orígen"
                class="ml-3 inputs"
                autocomplete="false"
                v-model="description"
                required
                maxlength="25"
                @keypress="alfaNumerico($event)"
              />
              <br />
              <br />
              <label v-if="vacio" style="color: red"
                >*Debe especificar el id y descripción del orígen del
                recurso</label
              >
            </div>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="aux()">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="validacion()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Aceptación de acciones-->
    <v-dialog v-model="advice" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">Aviso</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 style="text-align: center">{{ respuesta }}</h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="red"
            text
            @click="
              advice = false;
              idResource = '';
            "
            >Cancelar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="deleteItem()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Confirmación de datos-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Orígenes de recursos</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-lg-center text-sm-center text-md-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="confirmation = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      show: false,
      expiration: false,
      search: "",
      dialog: false,
      selectedItem: null,
      headers: [
        {
          text: "Id",
          align: "center",
          value: "id",
        },
        {
          text: "Descripción",
          align: "center",
          value: "descripcion",
        },
        {
          text: "Editar  / Eliminar",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      origins: [
        {
          id: "IP",
          descripcion: "Ingreso Propio",
        },
        {
          id: "IF",
          descripcion: "Ingreso Federales",
        },
        {
          id: "IM",
          descripcion: "Ingreso Mixto",
        },
      ],
      respuesta: "",
      advice: false,
      idResource: "",
      description: "",
      confirmation: false,
      edit: false,
      vacio: false,
      recursoId: "",
    };
  },
  methods: {
    editItem(item) {
      console.log("Ingreso a editar" + item.id);
      this.recursoId = item.id;
      this.idResource = item.claveCFDI;
      this.description = item.tipoOrigenRecurso;
      this.edit = true;
    },
    validacion: function () {
      this.errors = [];
      if (
        this.idResource == "" ||
        this.idResource == " " ||
        this.idResource == 0
      ) {
        this.errors.push("Se debe especificar el id del orígen del recurso.");
      }
      if (this.description == "" || this.description == " ") {
        this.errors.push(
          "Se debe especificar la descrpción del orígen del recurso."
        );
      }
      if (this.errors.length == 0) {
        this.edit = false;
        this.vacio = false;
        return this.editar();
      } else {
        this.vacio = true;
      }
    },
    editar() {
      //console.log("El id para la api es" + this.recursoId);
      this.respuesta = "";
      this.show = true;
      axios
        .put(
          Server + "/origenRecursos/" + this.recursoId,
          {
            Id: this.recursoId,
            ClaveCFDI: this.idResource,
            TipoOrigenRecurso: this.description,
          },
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          this.idResource = "";
          this.description = "";
          this.recursoId = "";
          this.respuesta = "El elemento fue actualizado con éxito";
          this.listar();
          this.show = false;
          this.confirmation = true;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.idResource = "";
            this.recursoId = "";
            this.description = "";
            this.respuesta = "El elemento no fue actualizado";
            this.confirmation = true;
          }
        });
    },
    /*
    temporal sirve para mostrar el nombre del dato a eliminar en la Alerta ayuda al usuario a no borrar el equivocado
    guarda solo el id del dato
    */
    temporal(item) {
      console.log("El id es: " + item.id);
      this.idResource = item.id;
      this.respuesta =
        "¿ Seguro que desea eliminar el elemento " +
        item.tipoOrigenRecurso +
        " ?";
      this.advice = true;
    },
    deleteItem() {
      this.advice = false;
      //console.log("Ingreso a eliminar el dato con id: " + this.idResource);
      this.respuesta = "";
      axios
        .delete(Server + "/origenRecursos/" + this.idResource, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.idResource = "";
          this.recursoId = "";
          this.respuesta = "El elemento fue eliminado con éxito";
          this.show = false;
          this.confirmation = true;
          this.listar();
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.idResource = "";
            this.recursoId = "";
            this.respuesta = "El elemento no fue eliminado";
            this.confirmation = true;
          }
        });
    },
    listar() {
      this.show = true;
      axios
        .get(Server + "/origenRecursos", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.origins = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    isLetter: function (evt) {
      var regex = new RegExp("^[a-zA-Z ]+$"); // Letras y espacio
      var str = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (regex.test(str)) {
        return true;
      }
      evt.preventDefault();
      return false;
    },
    alfaNumerico: function (evt) {
      var regex = new RegExp("^[a-zA-ZÀ-ÿ\u00f1\u00d10-9 ]+$"); // Letras,espacio y números
      var str = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (regex.test(str)) {
        return true;
      }
      evt.preventDefault();
      return false;
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if ((charCode > 31 && charCode < 48) || charCode > 57) {
        evt.preventDefault();
      } else return true;
    },
    aux() {
      this.edit = false;
      this.idResource = "";
      this.description = "";
      this.vacio = false;
    },
  },
  created() {
    this.listar();
  },
};
</script>